import React from 'react';
import classNames from 'classnames';
import { Agent } from '../types';

interface SidebarProps {
  modelName: string;
  loadingProgress: { progress: number; timeElapsed: number; text: string } | null;
  agents: Agent[];
  selectedAgentCallback: (agent: Agent) => void;
  reset : () => void;
}

function Sidebar({ modelName, loadingProgress, agents, selectedAgentCallback, reset }: SidebarProps) {
  const sidebarClass = classNames('w-full p-4 bg-gray-200 border-r border-gray-300 h-full overflow-y-auto');

  return (<div className={sidebarClass}>
      <div className="flex gap-1">
        <h2 className="text-2xl font-bold mb-4">What is an Agent?</h2>
        <button className="mt-2 p-2 bg-gray-300 text-gray-700 btn-rounded btn-shadow" onClick={() => reset()}>Reset</button>
      </div>
      <p className="mb-4 text-lg">
        An agent is a semi-autonomous entity which observes and acts upon an environment and directs its activity towards achieving goals.
      </p>
      <p className="mb-4 text-lg">
        The simplest of agents are chatbots that react to textual input and reply to questions. However, several chatbots can already do much more, like generating images (ChatGPT), artifacts (Claude), among other things.
      </p>
      <p className="mb-4 text-lg">
        What really differentiates a chatbot from a full-fledged agent is the ability to plan and perform one or more tasks autonomously based on the given input.
      </p>
      <p className="mb-4 text-lg">
        In the context of LLMs, agents can simulate conversations, perform tasks (search the web, retrieve information, send an email, ...), and interact with users or other agents.
      </p>
      <h3 className="text-xl font-semibold">Model: {modelName}</h3>
      {loadingProgress && (
        <div className="mt-4">
          <div className="flex items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
          </div>
          {loadingProgress.progress > 0 && (
            <>            
              <p className="mt-1 text-center text-sm">Loading: {loadingProgress.progress * 100}%</p>
              <p className="mt-1 text-center text-sm">Time Elapsed: {loadingProgress.timeElapsed}s</p>
            </>
            )}
        </div>
      )}
    {!loadingProgress && (
      <div className="mt-4">
        <h3 className="text-xl font-semibold">Agents</h3>
        <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
          {agents.map((agent) => (
            <div key={agent.name} className="bg-white p-4 rounded-lg shadow-md mb-4 flex flex-col justify-between">
              <h4 className="text-lg font-semibold">{agent.name}</h4>
              <p className="text-sm">{agent.description}</p>
              <div className="flex items-center mt-2">
          {agent.selected ? (
            <button className="mt-2 p-2 bg-gray-300 text-gray-700 btn-rounded btn-shadow" onClick={() => selectedAgentCallback(agent)}>Remove from Chat</button>
          ) : (
            <button 
              className="mt-2 p-2 bg-gray-300 text-gray-700 btn-rounded btn-shadow btn-info"
              onClick={() => selectedAgentCallback(agent) }>
              Add to Chat
            </button>
          )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )}

    <div className="absolute bottom-0 w-full p-2">
      <p className="text-sm">
        Based on <a href="https://webllm.mlc.ai/" className="text-blue-500 underline">https://webllm.mlc.ai/</a>
      </p>
    </div>
    </div>
  );
}

export default Sidebar;