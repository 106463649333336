import React from 'react';
import MessageItem from './MessageItem';
import { Agent, Message } from '../types';

interface MessageListProps {
  messages: Message[]
  engine: any;
  activeAgents: Agent[];
}

function MessageList({ messages, engine }: MessageListProps) {
  return (
    <div className="flex-1 overflow-y-auto p-4 bg-gray-100 rounded-lg shadow-md animate-fade-in mt-4 mb-4">
      {messages.map((msg) => (
        <MessageItem key={msg.id} text={msg.text} role={msg.role} agent={msg.agent} />
      ))}
      {!engine && (
        <p className="text-center text-gray-500 italic">Waking up agents...</p>
      )}
    </div>
  );
}

export default MessageList;