import React from 'react';
import classNames from 'classnames';

interface MessageProps {
  text: string;
  role: 'user' | 'assistant';
  agent?: string;
}

function MessageItem({ text, role, agent }: MessageProps) {
  const messageClass = classNames('p-3 rounded-lg mb-2 max-w-xs', {
    'bg-green-200 self-end': role === 'user',
    'bg-gray-200 self-start': role === 'assistant',
  });

  const containerClass = classNames('flex mb-2', {
    'justify-end': role === 'user',
    'justify-start': role === 'assistant',
  });

  return (
    <div className={containerClass}>
      <div className={messageClass}>
        {text}
        {role === 'assistant' && agent && <span className="text-xs text-gray-500 block mt-1">Agent: {agent}</span>}
      </div>
    </div>
  );
}

export default MessageItem;