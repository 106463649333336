import React, { useEffect, useState, useCallback } from 'react';
import { Agent, Message } from '../types';

interface MessageInputProps {
  addMessage: (text: string, role: 'user' | 'assistant', agent?: string) => void;
  engine: any;
  history : Message[];
  activeAgents: Agent[]
}

const MessageInput: React.FC<MessageInputProps> = ({ addMessage, engine, history, activeAgents }) => {
  const [input, setInput] = useState('');
  const [agentsInChat, setAgentsInChat] = useState<Agent[]>([]);
  const [agentWriting, setAgentWriting] = useState<{ [key: string]: boolean }>({});

  const handleNewAgents = useCallback((newAgents: Agent[]) => {
    setAgentsInChat(activeAgents);
    for (const agent of newAgents) {
      const systemMessage = {
        role: "system",
        content: `You are ${agent.name} with description: ${agent.description}. Act like a persona that would fit this description.  You are interacting in a whatsapp-like group chat, talk like you are in one. You will interact with humans and fellow virtual agents as well, and you were added to a group chat and you are not aware of the previous conversations in the group and you want to be part of it. If it fits your persona. Do not be mean or use inappropriate language. The following agents are online with you ${activeAgents.map(agent => agent.name).join(", ")}.`
      };
      let messages = [systemMessage].concat([{ role: "user", content: "Hello!" }]);
      setAgentWriting(prev => ({ ...prev, [agent.name]: true }));
      engine.chat.completions.create({
        messages,
      }).then((reply: { choices: { message: { content: string } }[] }) => {
        addMessage(reply.choices[0].message.content, 'assistant', agent.name);
        setAgentWriting(prev => ({ ...prev, [agent.name]: false }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAgents]);

  useEffect(() => {
    const newAgents = activeAgents.filter(agent => !agentsInChat.includes(agent));
    if (newAgents.length === 0) return;
    handleNewAgents(newAgents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });


  const handleSend = async () => {
    if (input.trim() && engine) {
      addMessage(input, 'user');
      setInput('');

      const shuffledAgents = [...activeAgents].sort(() => Math.random() - 0.5);

      const agentsNotWriting = shuffledAgents.filter(agent => !agentWriting[agent.name]);

      for (const agent of agentsNotWriting) {
        const systemMessage = {
          role: "system",
          content: `You are ${agent.name} with description: ${agent.description}. Act like a persona that would fit this description. You are interacting in a whatsapp-like group chat, talk like you are in one. You will interact with humans and fellow virtual agents as well. Do not be mean or use inappropriate language.`
        }

        let messages = [systemMessage].concat(history.map((msg) => {
          return {
        role: msg.role, 
        content: (msg.role === "assistant" ? msg.agent + ":" + msg.text : "human:" + msg.text)
          }
        }));

        messages = messages.concat({ role: "user", content: "human:" + input });

        (async () => {
          const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
          await sleep(Math.random() * 200 + 100);
          
          setAgentWriting(prev => ({ ...prev, [agent.name]: true }));
          const reply = await engine.chat.completions.create({
            messages,
          });
          setAgentWriting(prev => ({ ...prev, [agent.name]: false}));
          addMessage(reply.choices[0].message.content, 'assistant', agent.name);
        })();
      }
    }
  };

  return (
    <div className="flex flex-col w-full p-4 bg-gray-200 border-t border-gray-300 mb-5">
      {Object.keys(agentWriting).filter(agent => agentWriting[agent]).length > 0 && (
      <p className="text-sm text-gray-500 italic">
        {Object.keys(agentWriting).filter(agent => agentWriting[agent]).length} agent(s) typing...
      </p>
      )}
      <div className="flex items-center w-full">
        <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && handleSend()}
        placeholder={activeAgents.length === 0 ? "Please add an agent to the chat" : "Type a message"}
        className="flex-1 p-2 rounded-full border border-gray-300 mr-2 w-75"
        disabled={!engine || !activeAgents.length}
        />
        <button
        onClick={handleSend}
        className="p-2 bg-blue-500 text-white btn-rounded btn-shadow"
        disabled={!engine || !activeAgents.length}
        >
        Send
        </button>
      </div>
    </div>
  );
};

export default MessageInput;
